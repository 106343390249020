
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import Login from '~/components/v1/shares/Login'
// import Default from '~/layouts/default.vue'
// No message specified.
extend('email', email)
// Override the default message.
extend('required', {
  ...required,
  message: 'This field is required',
})
export default {
  name: 'LayoutLogin',
  components: {
    ValidationProvider,
    ValidationObserver,
    Login,
    // Default,
  },
  layout: 'empty',
  data: () => ({
    email: '',
    password: '',
    rememberMe: false,
    show1: false,
    msgErrors: null,
    msgErrorsAlert: false,
    socialMsgErrors: null,
    socialMsgErrorsAlert: false,
    loading: false,
    dialogs: {
      menuBar: false,
    },
  }),
  mounted() {
    // console.log(this.$vuetify.breakpoint.mobile)
  },
  methods: {
    async onSubmit() {
      // this.$refs.observer.validate().then((result) => {
      //   if (result) {
      //     console.log(result)
      //     alert('Form Submitted!')
      //     return
      //   }

      //   alert('Correct them errors!')
      // })
      this.loading = !this.loading
      this.invalid = !this.invalid
      this.msgErrorsAlert = false
      try {
        // await this.$axios.$get('sanctum/csrf-cookie')
        await this.$auth.loginWith('laravelJWT', {
          data: {
            email: this.email,
            password: this.password,
            provider: 'email',
            uid: null,
          },
        })
        // this.$router.push('/dashboard/posts')
        // console.log('log', data)
      } catch (error) {
        // console.log('log error', error.response)
        if (error.response.status === 401) {
          this.msgErrorsAlert = true
          this.msgErrors =
            'ไม่สามารถเข้าสู่ระบบได้ Email หรือ Password อาจไม่ถูกต้อง'
        }
        if (error.response.status === 500 || error.response.status === 404) {
          this.msgErrorsAlert = true
          this.msgErrors = 'มีบางอย่างผิดพลาดกรุณาติดต่อผู้ดูแลระบบ'
        }
        this.loading = !this.loading
        this.invalid = !this.invalid
      }
    },
    loginWithSocial(provider) {
      if (provider === 'facebook' || provider === 'google') {
        const authProvider =
          provider === 'google'
            ? new GoogleAuthProvider()
            : new FacebookAuthProvider()
        const auth = getAuth()
        signInWithPopup(auth, authProvider)
          .then(async (result) => {
            const user = result.user
            const data = {
              email: user.email,
              password: null,
              provider: provider,
              uid: user.uid,
            }
            try {
              await this.$auth.loginWith('laravelJWT', {
                data: data,
              })
              // this.$router.push('/dashboard/posts')
            } catch (error) {
              if (error.response.status === 401) {
                this.socialMsgErrorsAlert = true
                this.socialMsgErrors = 'ไม่มีสิทธิ์เข้าสู่ระบบได้'
              }
              if (error.response.status === 403) {
                this.$router.push('/how-to-register')
              }
              if (
                error.response.status === 500 ||
                error.response.status === 404
              ) {
                this.socialMsgErrorsAlert = true
                this.socialMsgErrors = 'มีบางอย่างผิดพลาดกรุณาติดต่อผู้ดูแลระบบ'
              }
              this.loading = !this.loading
              this.invalid = !this.invalid
            }
            // console.log(data)
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code
            const errorMessage = error.message
            // The email of the user's account used.
            const email = error.email
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error)
            // ...
            console.log(
              'code:' + errorCode,
              'msg:' + errorMessage,
              'email:' + email
            )
          })
        // console.log(provider)
      } else {
        this.$lineAuth.request()
      }
    },
    loginFacebook() {
      location.href = process.env.API_URL + '/api/auth/facebook/redirect'
      // this.$router.push('/facebook_callback')
    },
  },
}
