
export default {
  props: {
    name: {
      type: String,
      default() {
        return ''
      },
    },
    value: {
      type: [String, Number],
      default() {
        return ''
      },
    },
    type: {
      type: String,
      default() {
        return 'password'
      },
    },
    min: {
      type: Number,
      default() {
        return 0
      },
    },
    maxlength: {
      type: Number,
      default() {
        return 200
      },
    },
    autocomplete: {
      type: String,
      default() {
        return ''
      },
    },
    required: {
      type: Boolean,
      default() {
        return false
      },
    },
    readonly: {
      type: Boolean,
      default() {
        return false
      },
    },
    label: {
      type: String,
      default() {
        return ''
      },
    },
    placeholder: {
      type: String,
      default() {
        return ''
      },
    },
    error: {
      type: String,
      default() {
        return ''
      },
    },
    appendIcon: {
      type: String,
      default() {
        return ''
      },
    },
    appendIconImg: {
      type: String,
      default() {
        return ''
      },
    },
    appendIconPosition: {
      type: String,
      default() {
        return 'end'
      },
    },
    fontSize: {
      type: String,
      default() {
        return ''
      },
    },
    showError: {
      type: Boolean,
      default() {
        return null
      },
    },
    moreClass: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      id: null,
      firstTime: true,
      isFocus: false,
      showPassword: false,
    }
  },
  computed: {
    toShowError() {
      let result = false

      if (!this.firstTime) {
        if (this.showError !== null) {
          result = this.showError
        } else if (this.error !== '' && this.value === '') {
          result = true
        }
      }

      return result
    },
  },
  watch: {
    value(val) {
      this.updateValue(val)
    },
  },
  mounted() {
    this.id = this._uid
    this.firstTime = true
    this.$refs.input.value = this.inputValue(this.value)
    this.$refs.realInput.value = this.realInputValue(this.value)
  },
  methods: {
    onWheel($event) {
      $event.target.blur()
    },
    focus() {
      this.isFocus = true
      this.$refs.input.value = this.inputValue(this.$refs.input.value)
      this.$refs.realInput.value = this.realInputValue(this.$refs.input.value)
    },
    blur() {
      this.firstTime = false
      this.isFocus = false
      this.$refs.input.value = this.inputValue(this.$refs.input.value)
      this.$refs.realInput.value = this.realInputValue(this.$refs.input.value)
      this.$emit('on-blur', this)
    },
    keydown(e) {
      // if (this.type === 'number-comma' && !this.$util.isNumber(e.key)) { return e.preventDefault() }
    },
    reset() {
      this.firstTime = true
      this.updateValue('')
    },
    updateValue(value) {
      // this.$refs.input.value = (this.isFocus) ? value : this.inputValue(value)
      this.$refs.input.value = this.inputValue(value)
      this.$refs.realInput.value = this.realInputValue(value)
      this.$emit('input', this.emitValue(value))
    },
    emitValue(value) {
      if (this.type === 'number-comma') {
        return value.split(',').join('')
      }

      return value
    },
    inputValue(value) {
      if (this.type === 'number-comma') {
        if (!value || value === undefined || value.toString().trim() === '') {
          return ''
        }

        const tmp = parseFloat(value.toString().split(',').join(''))
        if (isNaN(tmp)) {
          return ''
        }
        return parseFloat(tmp).toLocaleString()
      } else if (this.type === 'phone-number' && typeof value === 'string') {
        const string = value.toString()
        let result = ''
        const pattern = /\d+/
        for (let i = 0; i < string.length; i++) {
          const c = string.charAt(i)
          if (pattern.test(c)) {
            result += c
          }
        }
        return result
      }

      return value
    },
    realInputValue(value) {
      if (this.type === 'number-comma') {
        if (!value || value === undefined || value.toString().trim() === '') {
          return ''
        }

        const tmp = parseFloat(value.toString().split(',').join(''))
        if (isNaN(tmp)) {
          return ''
        }
        return tmp
      } else if (this.type === 'phone-number' && typeof value === 'string') {
        const string = value.toString()
        let result = ''
        const pattern = /\d+/
        for (let i = 0; i < string.length; i++) {
          const c = string.charAt(i)
          if (pattern.test(c)) {
            result += c
          }
        }
        return result
      }
      return value
    },
    appendIconClick() {
      this.$emit('append-icon-click', this)
    },
  },
}
