
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import TextInput from './TextInput'
import PasswordInput from './PasswordInput'
// No message specified.
extend('email', email)
// Override the default message.
extend('required', {
  ...required,
  message: 'This field is required',
})
export default {
  name: 'LayoutLogin',
  components: {
    ValidationProvider,
    ValidationObserver,
    TextInput,
    PasswordInput,
  },
  layout: 'empty',
  data: () => ({
    email: '',
    password: '',
    rememberMe: false,
    show1: false,
    socialMsgErrors: null,
    socialMsgErrorsAlert: false,
    loading: false,
    dialogs: {
      menuBar: false,
    },
    showModalStatus: false,
    invalid: true,
    apiFailed: false,
    errorMsg: '',
  }),
  computed: {
    propertyOfInvalid() {
      return `${this.email} | ${this.password}`
    },
  },
  watch: {
    propertyOfInvalid() {
      if (
        this.email !== '' &&
        this.isEmailOrPhone(this.email) &&
        this.password !== '' &&
        this.password.length >= 6
      ) {
        this.invalid = false
      } else {
        this.invalid = true
      }
    },
  },
  methods: {
    async onSubmit() {
      this.loading = !this.loading
      this.invalid = !this.invalid
      this.apiFailed = false
      const el = [
        ...this.$refs['form-login'].$el.querySelectorAll(
          '.form-group input.form-control'
        ),
      ]
      el.map((el) => {
        el.classList.remove('error')
      })
      try {
        const response = await this.$auth.loginWith('laravelJWT', {
          data: {
            login: this.email,
            password: this.password,
            // provider: 'email',
            // uid: null,
          },
        })
        if (response.data.success === true) {
          this.loading = !this.loading
          this.$store.commit('SET_ERROR_CODE', 200)
          this.$store.commit('FIRST_LOGIN', true)
          // this.$router.push('/dashboard/posts')
        } else {
          el.map((el) => {
            el.classList.add('error')
          })
          this.apiFailed = true
          this.loading = !this.loading
          this.invalid = !this.invalid
          this.errorMsg = response.data.msg
        }
      } catch (error) {
        el.map((el) => {
          el.classList.add('error')
        })
        this.apiFailed = true
        this.loading = !this.loading
        this.invalid = !this.invalid
      }
    },
    loginWithSocial(provider) {
      if (provider === 'facebook' || provider === 'google') {
        const authProvider =
          provider === 'google'
            ? new GoogleAuthProvider()
            : new FacebookAuthProvider()
        const auth = getAuth()
        signInWithPopup(auth, authProvider)
          .then(async (result) => {
            const user = result.user
            const data = {
              // email: user.email,
              // password: null,
              provider: provider,
              uid: user.uid,
            }
            try {
              await this.$auth.loginWith('laravelJWT', {
                data: data,
              })
              this.$store.commit('SET_ERROR_CODE', 200)
              this.$store.commit('FIRST_LOGIN', true)
              // this.$router.push('/dashboard/posts')
            } catch (error) {
              this.$store.commit('SET_ERROR_CODE', error.response.status)
              this.$store.commit('FIRST_LOGIN', true)
              this.$router.push('/how-to-register')
              this.loading = !this.loading
              this.invalid = !this.invalid
            }
            // console.log(data)
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code
            const errorMessage = error.message
            // The email of the user's account used.
            const email = error.email
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error)
            // ...
            console.log(
              'code:' + errorCode,
              'msg:' + errorMessage,
              'email:' + email
            )
          })
        // console.log(provider)
      } else {
        this.$lineAuth.request()
      }
    },
    loginFacebook() {
      location.href = process.env.API_URL + '/api/auth/facebook/redirect'
      // this.$router.push('/facebook_callback')
    },
    isEmailOrPhone(value) {
      const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      const phoneReg = /^\d{10,10}$/
      if (emailReg.test(value) || phoneReg.test(value)) {
        return true
      } else {
        return false
      }
    },
    toNayooResetPassword() {
      location.href = `${process.env.NAYOO_WEB_URL}/khonkaen?mode=reset_from_agent`
      // http://nayoo.local:8080/khonkaen?mode=reset_from_agent
    },
  },
}
